import axios from "@/axios"

const url = "/api/postura"

class PosturasService {
	async savePostura(arrayPosturas) {
		let posturas = arrayPosturas.map((a) => ({
			madrilla_id: a.madrilla.id,
			padrillo_id: a.padrillo.id || a.padrillo,
			observation: a.observation,
			postura_date: a.postura_date,
			active: a.active || 0,
		}))

		try {
			const { data } = await axios.post(`${url}/save`, posturas)
			return data
		} catch (error) {
			const { status, response } = error
			console.log(response.data.message)
			return { status, message: response.data.message }
		}
	}

	async getPosturasByStatus({ perPageIn, pageIn, statusIn, typeSearchIn, start_date, end_date }) {
		try {
			const { data } = await axios.post(`${url}/get-by-status`, {
				statusIn,
				typeSearchIn,
				start_date,
				end_date,
				perPageIn,
				pageIn,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getPosturasByStatusAndEggs({
		perPageIn,
		pageIn,
		statusIn,
		typeSearchIn,
		start_date,
		end_date,
		searchIn,
		grouped,
	}) {
		try {
			const { data } = await axios.post(`${url}/get-by-status-and-eggs`, {
				statusIn,
				typeSearchIn,
				start_date,
				end_date,
				perPageIn,
				pageIn,
				searchIn,
				grouped,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	/**
	 * @param {string|number} year
	 *
	 * @return {Promise<Array>}
	 */
	async getTreePosturas({ start_date, end_date }) {
		try {
			const { data } = await axios.post(`${url}/get-pdf`, { start_date, end_date })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async updateSeenPostura({ idPostura }) {
		try {
			const resp = await axios.post(`${url}/update-seen-postura`, {
				idPostura,
			})
			return resp
		} catch (err) {
			console.error(err)
		}
	}

	async getTreePosturas() {
		try {
			const { data } = await axios.post(`${url}/get-pdf`)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getCountersPosturas({ start_date, end_date, specimen_id }) {
		try {
			const { data } = await axios.post(`${url}/counters`, { start_date, end_date, specimen_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async cancelPostura({ postura_id, textTrackingIn }) {
		try {
			const { data } = await axios.post(`${url}/cancel`, { postura_id, textTrackingIn })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async activatePostura({ postura_id, method_id }) {
		try {
			const { data } = await axios.post(`${url}/activate`, { postura_id, method_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async finishPostura({ postura_id, reason_text, status_madrilla_id = null }) {
		try {
			const { data } = await axios.post(`${url}/finish`, { postura_id, reason_text, status_madrilla_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	/**
	 * @param {number} category_id   The specimen category id
	 * @param {string} year_start    The year of start the season
	 * @param {string} years_end 	 The date of end the season
	 *
	 * @return {Promise<Array<Object<string, any>>>}
	 */
	async fetchPostureByGenderAndYear(category_id, year_start, year_end) {
		try {
			const { data: response } = await axios.get("api/posturas/get-posture-by-gender-and-year", {
				params: {
					category_id,
					year_start,
					year_end,
				},
			})

			return response
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} category_id   The specimen category id
	 * @param {number} specimen_id   The specimen category id
	 * @param {number} status_id     The specimen category id
	 * @param {string} year_start    The year of start the season
	 * @param {string} years_end 	 The date of end the season
	 *
	 * @return {Promise<Array<Object<string, any>>>}
	 */
	async fetchPostureBySpecimenAndStatus({ category_id, specimen_id, status_id, year_start, year_end }) {
		try {
			const { data: response } = await axios.get("api/posturas/get-posture-by-specimen-and-status", {
				params: {
					category_id,
					specimen_id,
					status_id,
					year_start,
					year_end,
				},
			})

			return response
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} category_id   The specimen category id
	 * @param {number} year   the year of season
	 *
	 * @return {Promise<Array<Object<string, any>>>}
	 */
	async fetchSpecimensWithoutPostures(params) {
		try {
			const { data } = await axios.get("api/posturas/get-specimens-without-postures", { params })

			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} category_id   The specimen category id
	 * @param {number} year_start   the year of season
	 * @param {number} year_end   the year of season
	 *
	 * @return {Promise<Object<string, any>>}
	 */
	async fetchPostureCounterByParentsGender(category_id, year_start, year_end) {
		try {
			const { data: response } = await axios.get("/api/posturas/counter-by-parents-gender", {
				params: {
					category_id,
					year_start,
					year_end,
				},
			})

			return response
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} category_id
	 * @param {number} specimen_id
	 *
	 * @return {Promise<Array<Object<string, any>>}
	 */
	async fetchPostureBySpecimenCategory(category_id, specimen_id) {
		try {
			const { data: response } = await axios.get(`${url}/get-posturas-by-specimen-category`, {
				params: {
					category_id,
					specimen_id,
				},
			})
			return response
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} specimen_id
	 * @param {number} category_id
	 */
	async fetchPostureBySpecimenId({ specimen_id, category_id, status_in, start_date, end_date }) {
		// console.log('add', specimen_id);
		try {
			const { data: response } = await axios.get(`${url}/get-posturas-by-specimen`, {
				params: {
					category_id,
					specimen_id,
					statusIn: status_in,
					start_date,
					end_date,
				},
			})
			return response
		} catch (error) {
			throw error
		}
	}

	/**
	 * @param {number} specimen_id
	 * @param {number} category_id
	 */
	async fetchPostureWithEggsBySpecimenId({ specimen_id, category_id, status, dateIn, dateFn }) {
		try {
			const { data: response } = await axios.get(`${url}/get-posturas-and-eggs-by-specimen`, {
				params: {
					category_id,
					specimen_id,
					status,
					dateIn,
					dateFn,
				},
			})
			return response
		} catch (error) {
			throw error
		}
	}

	async changePendingPosturaPadrillo({ postura_id, new_padrillo_id }) {
		const { data } = await axios.put(`/api/posturas/assign-new-padrillo`, {
			postura_id,
			new_padrillo_id,
		})
		return data
	}

	async getPosturaChildsDead({ postura_id }) {
		const { data } = await axios.get(`/api/posturas/get-childs-dead-by-posture/${postura_id}`)
		return data
	}

	async getPosturaChildsAlive({ postura_id }) {
		const { data } = await axios.get(`/api/posturas/get-childs-alive-by-posture/${postura_id}`)
		return data
	}

	async releaseSpecimenFromResting({ specimen_id }) {
		const { data } = await axios.put(`/api/posturas/change-status-to-clean`, {
			specimen_id,
		})
		return data
	}

	async getMadrillasWithoutEggs({ search, page, perpage, orderBy }) {
		try {
			const { data } = await axios.get(`${url}/get-check-madrillas`, {
				params: {
					search,
					page,
					perpage,
					orderBy,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getCounterMadrillasWithoutEggs() {
		const { data } = await axios.get(`${url}/get-check-madrillas/counter`)
		return data
	}

	async getMadrillasWithPendingDeworming() {
		try {
			const { data } = await axios.get(`${url}/get-check-madrillas/get-pending-deworming`)
			return data
		} catch (error) {
			return error.response.data
		}
	}
	async getMadrillasWithCompletedDeworming(params) {
		try {
			const { data } = await axios.get(`${url}/get-check-madrillas/get-completed-deworming`, { params })
			return data
		} catch (error) {
			return error.response.data
		}
	}
	async dewormMadrilla(deworming_id) {
		try {
			const { data } = await axios.put(`${url}/get-check-madrillas/deworm-madrilla/${deworming_id}`)
			return data
		} catch (error) {
			return error.response.data
		}
	}
}

export default new PosturasService()
