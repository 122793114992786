import decrestService from "@/services/header/decrest.service"
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import SpecimenService from "@/services/specimens.service.js"
import PostureService from "@/services/posturas.service.js"
import IncidencesService from "@/services/incidences.service"
import commonsService from "@/services/commons.service"
import CorralChicksService from "@/services/encaste/corral.service"
export default {
	namespaced: true,
	state: {
		S_DECREST_COUNTER: 0,
		S_RECOMMENDED_COUNTER: 0,
		S_DAYS_WITHOUT_TOPES_COUNTER: [],
		S_CHECK_MADRILLAS_COUNTER: 0,
		S_INCIDENCES_COUNTER: 0,
		S_FEMALE_FOR_DEPURATION_COUNTER: 0,
		S_DEPURATIONS_FOR_ADMIN: 0,
		S_DEPURATIONS_FOR_ENCASTADOR: 0,
	},
	getters: {
		G_GET_DECREST_COUNTER(state) {
			return state.S_DECREST_COUNTER
		},
		G_GET_RECOMMENDED_COUNTER(state) {
			return state.S_RECOMMENDED_COUNTER
		},
		G_GET_DAYS_WITHOUT_TOPES_COUNTER(state) {
			return state.S_DAYS_WITHOUT_TOPES_COUNTER
		},
		G_GET_FEMALE_FOR_DEPURATION_COUNTER(state) {
			return state.S_FEMALE_FOR_DEPURATION_COUNTER
		},
		G_GET_CHECK_MADRILLAS_COUNTER(state) {
			return state.S_CHECK_MADRILLAS_COUNTER
		},
		G_GET_INCIDENCES_COUNTER(state) {
			return state.S_INCIDENCES_COUNTER
		},
		G_DEPURATIONS_FOR_ADMIN(state) {
			return state.S_DEPURATIONS_FOR_ADMIN
		},
		G_DEPURATIONS_FOR_ENCASTADOR(state) {
			return state.S_DEPURATIONS_FOR_ENCASTADOR
		},
	},
	mutations: {
		M_UPDATE_COUNTER(state, payload) {
			state.S_DECREST_COUNTER = payload
		},
		M_UPDATE_COUNTER_RECOMMENDED(state, payload) {
			state.S_RECOMMENDED_COUNTER = payload
		},
		M_UPDATE_DAYS_WITHOUT_TOPES_COUNTER(state, payload) {
			state.S_DAYS_WITHOUT_TOPES_COUNTER = payload
		},
		M_UPDATE_FEMALE_FOR_DEPURATION_COUNTER(state, payload) {
			state.S_FEMALE_FOR_DEPURATION_COUNTER = payload
		},
		M_UPDATE_CHECK_MADRILLAS_COUNTER(state, payload) {
			state.S_CHECK_MADRILLAS_COUNTER = payload
		},
		M_UPDATE_INCIDENCES_COUNTER(state, payload) {
			state.S_INCIDENCES_COUNTER = payload
		},
		M_UPDATE_DEPURATIONS_FOR_ADMIN(state, payload) {
			state.S_DEPURATIONS_FOR_ADMIN = payload
		},
		M_UPDATE_DEPURATIONS_FOR_ENCASTADOR(state, payload) {
			state.S_DEPURATIONS_FOR_ENCASTADOR = payload
		},
	},
	actions: {
		async A_GET_DECREST_COUNTER({ commit }) {
			const { data } = await decrestService.getDecrestedCounters()
			let value = data.find((c) => c.id == 1).counter
			commit("M_UPDATE_COUNTER", value)
		},
		async A_GET_RECOMMENDED_COUNTER({ commit }, role) {
			const { data } = await RecommendedSpecimenService.getRecommendedCounter(role)
			let value = data.data.counter
			commit("M_UPDATE_COUNTER_RECOMMENDED", value)
		},
		async A_GET_DAYS_WITHOUT_TOPES_COUNTER({ commit }) {
			const { data } = await SpecimenService.getCounterDaysWithoutTopes()
			let value = data
			commit("M_UPDATE_DAYS_WITHOUT_TOPES_COUNTER", value)
		},
		async A_GET_FEMALE_FOR_DEPURATION_COUNTER({ commit }) {
			const { data } = await commonsService.getDepurationCounter()
			let value = data
			commit("M_UPDATE_FEMALE_FOR_DEPURATION_COUNTER", value)
		},
		async A_GET_CHECK_MADRILLAS_COUNTER({ commit }) {
			const { data } = await PostureService.getCounterMadrillasWithoutEggs()
			let value = data
			commit("M_UPDATE_CHECK_MADRILLAS_COUNTER", value)
		},
		async A_GET_INCIDENCES_COUNTER({ commit }) {
			const { data } = await IncidencesService.getIncidencesCounter()
			let value = data
			commit("M_UPDATE_INCIDENCES_COUNTER", value)
		},
		async A_GET_DEPURATION_COUNTERS({ commit }) {
			const { data } = await CorralChicksService.getDepurationRequestCounters()
			commit("M_UPDATE_DEPURATIONS_FOR_ADMIN", data.admin)
			commit("M_UPDATE_DEPURATIONS_FOR_ENCASTADOR", data.encastador)
		},
	},
}
