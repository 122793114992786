export default [
	{
		path: "/management",
		name: "management",
		redirect: { name: "management-petty-cash" },
		component: () => import("@/views/amg/management/views/ManagementMain.vue"),
		children: [
			{
				path: "petty-cash",
				name: "management-petty-cash",
				component: () => import("@/views/amg/management/views/petty-cash/PettyCash.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Administracion",
					breadcrumb: [
						{
							text: "Caja chica",
							active: false,
						},
						{
							text: "Lista",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "warehouse",
				name: "management-warehouse",
				component: () => import("@/views/amg/management/views/warehouse/Warehouse.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Administracion",
					breadcrumb: [
						{
							text: "Productos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
]
